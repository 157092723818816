<template>
  <div class="pb-4">
    <div class="Mail__header"></div>
    <div class="Mail">
      <div>
        <div class="Mail__container container">
          <div class="text-center">
            <img class="Mail__logo" :src="logo" alt="logo" />
          </div>
          <h1 class="text-center Mail__headings py-4">Madame, Monsieur,</h1>
          <div class="py-3">
            <p class="Mail__headings text-center">
              Merci d’avoir effectué une commande chez
              <span class="Mail__headings alternate">Sidi Harazem.</span>
            </p>

            <p class="Mail__headings text-center">
              Celle-ci est actuellement en cours de traitement.
            </p>
          </div>
          <div class="py-3">
            <h1 class="text-center Mail__headings2">Elle porte le numéro</h1>
            <p class="text-center Mail__headings2 number mx-auto mt-2">
              21075418781663
            </p>
          </div>
          <p class="text-center Mail__text pb-3">
            Ce numéro vous permet de suivre l’état d’avancement de votre
            commande.<br />
            Nous vous conseillons de le conserver précieusement.
          </p>
          <h1 class="text-center Mail__headings">
            Récapitulatif de la commande
          </h1>
          <hr class="Mail__horizontalLine" />
          <Products />
          <hr class="Mail__horizontalLine" />
          <div
            class="
              d-flex
              justify-content-between
              px-5
              Mail__products__totalContainer
              mb-2
            "
          >
            <p class="Mail__products___description__text">Total des produits</p>
            <p class="Mail__products___description__subtotal">66 DH</p>
          </div>
          <div
            class="
              d-flex
              justify-content-between
              px-5
              Mail__products__totalContainer
              mb-2
            "
          >
            <p class="Mail__products___description__text">Frais de livraison</p>
            <p class="Mail__products___description__text">Offert</p>
          </div>
          <div
            class="
              d-flex
              justify-content-between
              px-5
              Mail__products__totalContainer
              mb-2
            "
          >
            <p class="Mail__products___description__totaltext">Total TTC</p>
            <p class="Mail__products___description__total">66 DH</p>
          </div>
          <hr class="Mail__horizontalLine" />
          <p class="Mail__footer__text1 text-center mb-2">
            Vos retours d’expérience permettront d’améliorer le dispositif et
            d’en faciliter l’usage pour tous. <br />
            N’hésitez pas à nous faire part de vos observations sur notre site
            internet <a href="#" target="_blank">www.sidiharazem.ma</a> ou à
            travers notre service consommateurs au 06 08 051 051
          </p>
          <p class="Mail__footer__text2 text-center mb-2">
            Ce message est automatique, veuillez ne pas y répondre.<br />
            Pour nous contacter, merci d’utiliser l’adresse
            <a href="mailto:contact@sidiharazem.ma">contact@sidiharazem.ma</a>
          </p>
        </div>
      </div>
    </div>
    <hr class="Mail__footer__horizontalLine long" />
    <div>
      <p class="Mail__footer__text3 text-center mb-2">{{ $t("social") }}</p>
      <div class="Mail__social d-flex justify-content-center">
        <div class="d-flex">
          <a href="https://www.facebook.com/EAUSIDIHARAZEM" target="_blank">
            <img class="Mail__social__icon" :src="fb" alt="" />
          </a>
          <a
            href="https://www.instagram.com/sidiharazemofficiel/"
            target="_blank"
          >
            <img class="Mail__social__icon ml-2" :src="insta" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Products from "@/components/EmailTemplate/Products.vue";
export default {
  components: { Products },
  data() {
    return {
      logo: require("../assets/svgs/logo.svg"),
      fb: require("../assets/images/mail/fb.svg"),
      insta: require("../assets/images/mail/insta.svg"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
