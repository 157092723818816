<template>
  <div>
    <div
      class="Mail__products d-flex justify-content-between px-5 mb-4"
      v-for="(price, i) in prices"
      :key="i"
    >
      <div class="d-flex align-items-baseline">
        <div class="Mail__products__productImageContainer">
          <img
            class="img-fluid Mail__products__img"
            :src="pack"
            alt="product pack image"
          />
        </div>
        <div class="m-0 Mail__products___description ml-3">
          <p class="m-0 Mail__products___description__text">
            Bouteille PET 0.5L
          </p>
          <p class="m-0 Mail__products___description__text">
            en pack de 12 bouteilles
          </p>
        </div>
      </div>
      <p class="m-0 Mail__products___description__text price">30 DH</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pack: require("../../assets/images/mail/pack.png"),
      prices: ["30 DH", "36 DH"],
    };
  },
};
</script>

<style lang="scss" scoped></style>
